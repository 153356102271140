import { useOktaAuth } from "@okta/okta-react";
import { UserClaims, CustomUserClaims } from "@okta/okta-auth-js";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

export const UserContext = createContext<UserClaims<CustomUserClaims> | null>(
  null
);

export const UserContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims> | null>(
    null
  );
  const oktaAuthState = useOktaAuth();

  useEffect(() => {
    if (oktaAuthState.authState?.isAuthenticated) {
      oktaAuthState.oktaAuth.getUser().then((user) => {
        setUserInfo(user);
      });
    }
    return () => {
      setUserInfo(null);
    };
  }, [oktaAuthState.authState?.isAuthenticated]);

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
